@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "MazdaTypeTT-Regular", "Verdana", "sans-serif";
$boldFontFamily: "MazdaTypeTT-Bold", "Verdana", "sans-serif";
$boldFontWeight: normal;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #101010;
$linkColor: #999999;
$labelColor: #999999;
$formColor: #101010;
$utilColor: #101010;
$priceColor: #101010;
$lightColor: #999999;
$btnColorAlpha: #101010;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #101010;
$titleSecondaryColor: #ffffff;
$subtitleColor: #101010;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #910a2d;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f5f5f5;
$bgThirdColor: #101010;

$bgBtnAlpha: #ffffff;
$colorBtnAlpha: #fff;
$bgBtnBeta: #910a2d;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #101010;
$colorBtnOutlineBeta: #101010;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #101010;
$borderSizeForm: 1px solid;

$borderColorSort: #d0d0d0;

$calculatorTabsColor: #ffffff;
$calculatorTabsTextColor: #999999;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #910a2d;

$garageBackground: #ffffff;
$garageTextColor: #101010;
$garageCarNoteColor: #f5f5f5;

$bgTopbarLogin: #101010;
$colorTopbarLogin: #ffffff;

$progressBarTitleBG: #999999;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #999999;
$progressBarCloseButtonBG: #999999 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #101010;
$paymentCalculatorClosedColor: #ffffff;
$paymentCalculatorPanelBG: #101010;
$paymentCalculatorTermWrapperBG: #d5d5d5;
$paymentCalculatorTermWrapperColor: #999999;
$paymentCalculatorTermSelectedWrapperBG: #101010;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #999999;
$paymentCalculatorTermColor: #101010;

// ========
// Styles
// ========

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__bay-mazda{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }

        .sr-link{
            &:hover{
                opacity: 1!important;
                color: #101010;
            }
        }

        .sr-button-1,
        .sr-button-outline-2{
            box-shadow: none!important;
            border: 2px solid #101010!important;
            border-radius: 3px!important;
            width: auto;
            min-width: initial!important;
            padding: 15px 23px!important;
            margin: 0 auto;
            &:hover, &:focus{
                box-shadow: none;
                opacity: 1!important;
                background: #910a2d;
                color: #fff;
                border-color: #910a2d!important;
            }
            &.is-small{
                text-transform: uppercase!important;
            }
        }

        .sr-button-outline-2 {
            &.is-alternate {
                background-color: $bgThirdColor;
            }
            &.is-alternate:hover {
                background-color: $bgBtnBeta;
            }
        }
        .message-handler.warning {
            background-color: $bgThirdColor;
        }
        .sr-progressBar-closeButton{
            text-transform: uppercase;
        }

        .btn-register.button.sr-button-1{
            padding: 10px 23px!important;
        }

        .btn-login{
            text-transform: uppercase;
        }
        .btn-step.next {
            .icon-Arrow-Left-2::before {
                content: "";
            }
        }
        .btn-step.previous {
            .icon-Arrow-Left-2::before {
                content: "";
            }
        }

        .TransactionSummary--wrapper--title span{
            text-transform: uppercase;
        }
        .InputText .input-control{
            border: 1px solid #D5D5D5;
            &:active, &:focus {
                border: 1px solid #101010;
                box-shadow: none;
            }
        }

        .sr-form .field-error input[type=text]:focus, 
        form .field-error input[type=text]:focus, 
        .field-error.InputText .input-control{
            border: 2px solid #910a2d!important;
        }

        [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea{
            font-style: normal;
        }

        .sr-form input[type=checkbox]+label:before, 
        .sr-form input[type=radio]+label:before, 
        form input[type=checkbox]+label:before, 
        form input[type=radio]+label:before{
            border: 1px solid #d5d5d5;
            border-radius: 0;
        }

        .sr-form input[type=checkbox]:checked+label:before, 
        .sr-form input[type=radio]:checked+label:before, 
        form input[type=checkbox]:checked+label:before, 
        form input[type=radio]:checked+label:before{
            border: 1px solid #101010!important;
            background: transparent;
            border-radius: 0;
        }

        .sr-form input[type=checkbox]+label:after, 
        .sr-form input[type=radio]+label:after, 
        form input[type=checkbox]+label:after, 
        input[type=radio]+label:after{
            background: transparent;
        }

        .sr-form input[type=checkbox]:checked+label:after, 
        .sr-form input[type=radio]:checked+label:after, 
        form input[type=checkbox]:checked+label:after, 
        input[type=radio]:checked+label:after{
            background-color: #101010;
            border-radius: 0;
            left: 3px;
        }

        .forgotPasswordForm .error-message, 
        .form-login .error-message, 
        .registerForm .error-message, 
        .resetPasswordForm .error-message{
            color: #910a2d!important;
        }


        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:before, 
        .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before{
            color: #d5d5d5!important;
            border-color: #d5d5d5!important

        }

        .widget-profile-popupElement form#profileForm [type=checkbox]+label:before, 
        .widget-profile-popupElement form#profileForm input[type=radio]+label:before{
            color: #d5d5d5!important;
            border: 1px solid #d5d5d5!important;
        }

        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:after,
        .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:after{
            background-color: #101010;
            border-radius: 4px;
            left: 5px;
            width: 8px;
            height: 8px;
        }

        .widget-profile-popupElement form#profileForm .login-radio{
            color: #101010;
        }

        .TransactionProgressBar--wrapper{
            background-color: #d5d5d5;
        }

        .TransactionAction--single__amount.sr-button-1.is-small{
            box-shadow: 0 1px 4px 0 rgba(0,0,0,.3)!important;
            border: none!important;
            border-radius: 50%!important;
            padding: 3px 6px!important;
        }

        .TransactionActionDetails--wrapper .sr-button-1{
            margin-right: 20px;
        }

        .TransactionActionDetails--wrapper .earnings_listing{
            margin-bottom: 20px;
        }

        .TransactionActionDetails--wrapper>div{
            padding: 20px 30px 40px;
        }

        .sr-button-outline-1{
            border: 2px solid #101010!important;
            border-radius: 3px!important;
            text-transform: uppercase!important;
            padding: 15px 23px!important;
        }

        .sr-button-outline-1:hover{
            opacity: 1;
            background: #910a2d!important;
            color: #ffffff!important;
            border: 2px solid #910a2d!important;
            border-radius: 3px;
        }
        .sr-button-outline-1:hover:not(.is-disabled){
            opacity: 1!important;
        }
        .sr-button-outline-2.is-hover, 
        .sr-button-outline-2.is-hover:hover{
            background: #910a2d!important;
            border-color: #910a2d!important;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single{
            color: #d5d5d5;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #101010;
        }
        .selection-year-bloc:hover{
            border-color: #101010;
        }
        .selection-year-bloc:hover .selection-year-bloc__selectButton{
            background-color: #101010;
        }
        .paymentCalculator__buttons #calculator-toggle-button{
            top: 50%;
            position: relative;
            transform: translateY(-50%);
            height: 45px!important;
        }
        .paymentCalculator__payment .is-util{
            color: #ffffff;
        }
        .section__next-prev-step .sr-button-1{
            margin-left: 15px;
        }
        .section__next-prev-step .sr-button-1.is-darker{
            background: #101010;
            color: #ffffff;
        }
        .section__next-prev-step .sr-button-1.is-darker:hover{
            background: #910a2d;
            color: #ffffff;
        }
        .payment-calculator-wrapper.is-open .paymentCalculator__buttons #calculator-toggle-button icon{
            font-size: 15px
        }
        .payment-calculator-wrapper.is-open .paymentCalculator__buttons #calculator-toggle-button{
            background: #101010;
            color: #ffffff;
        }
        .payment-calculator-wrapper.is-open .paymentCalculator__buttons #calculator-toggle-button:hover,
        .payment-calculator-wrapper.is-open .paymentCalculator__buttons #calculator-toggle-button:focus{
            border-color: #101010!important;
        }
        .sr-calculator-tabs li.current{
            border-color: #910a2d!important;
            color: #101010;
        }
        .new-customize-payment,
        .calculator-cash-down,
        .section-distance{
            border-bottom: 1px solid #d5d5d5;
        }
        .calculatorColumn{
            border-right: 1px solid #d5d5d5;
            .sr-link:hover {
                color: $titleSecondaryColor;
            }

        }
        .calculator-done{
            border-top: 1px solid #d5d5d5;
        }
        .calculator-btn span.is-small.is-bold, .calculator-btn icon.icon-calculatrice{
            font-size: 30px;
        }
        .calculatorColumn:last-child{
            border: 0;
        }
        .payment-detail .is-util{
            color: #999999;
        }
        .sr-paymentCalculator-Panelbackground .purchase-detail-line .is-util{
            color: #fff;
        }
        .price.is-util{
            color: #999999;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .term{
            background-color: #910a2d!important;
            color: #ffffff;
        }
        .sr-paymentCalculator__calculatorTerm--background .price span{
            color: #999999!important;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected .price span{
            color: #999999!important;
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control, 
        .sr-paymentCalculator-dropdown .Select-control, 
        .sr-paymentCalculator-dropdown .Select-option,
        .InputNumber input,
        .InputNumber--add, 
        .InputNumber--minus{
            background-color: #101010!important;
            border: 1px solid #d5d5d5!important;
        }
        .calculator-cash-down-wrapper {
          .InputNumber {
              input:hover {
                  color: $titleSecondaryColor;
              }
              input:focus {
                color: $titleSecondaryColor;
            }
          }  
        }
        .style-color__button {
            border: 5px solid #d5d5d5;
            box-shadow: none;
        }
        .style-color__button.selected {
            border: 0;
            &:after{
                content: '';
                top: 9px;
                left: 12px;
                border-bottom: 1px solid;
                border-right: 1px solid;
                width: 7px;
                height: 11px;
                position: absolute;
                transition: all .2s;
                border-bottom: 2px solid;
                border-right: 2px solid;
                display: block;
                width: 6px;
                height: 10px;
                color: #fff;
                transform: rotate(45deg);
            }
        }
        .bloc-options__single.selected{
            background: #101010;
        }
        .bloc-options__single--footer.selected{
            color: #fff;
            background-color: transparent;
        }
        .bloc-options__single--footer.selected .option-choose-cta{
            background-color: #910a2d;
            color: #fff;
            border-color: #910a2d;
        }
        .bloc-options__single{
            background: #e7e7e7;
        }
        .bloc-options__single--footer{
            background-color: #e7e7e7;
            color: #101010;
        }
        .accessory_wrapper.single .accessory_wrapper--check icon{
            color: #fff;
        }

        .HeaderMessage--content .widget-vehicle-quote-btn-secondary{
            margin-left: 20px;
            border-color: #fff!important;
            &:hover{
                border-color: #910a2d!important;
            }
        }
        .section-summary__content-header .sr-button-2,
        .vdp-wrapper-warranties .sr-button-2{
            color: #fff;
            background: #101010;
            box-shadow: none;
            border-radius: 3px;
            &:hover{
                background: #910a2d;
                opacity: 1;
            }
        }
        .section-summary__content .trim,
        .financing-section.tile-payment-info .tile-frequency,
        .financing-section.tile-payment-info .tile-payment-cashdown,
        .financing-section.tile-payment-info .tile-payment-rate,
        .financing-section.tile-payment-info .tile-payment-amount span,
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .car-equipments_summary .specs-bloc,
        .car-equipments_summary .features-bloc,
        .vehicle-overview__vehicle-title .trim,
        .vehicle-overview__vehicle-title .current-package,
        .TradeInTile--content-official-subtitle{
            color: #101010;
        }
        .car-equipments_summary .specs-bloc--content span,
        .TradeInTile--content-estimate-text .sr-text,
        .VehicleDisclaimersContent p{
            color: #999999;
        }
        .TradeInTile--content-estimate,
        .instant-estimate__pattern{
            background-color: #101010!important;
        }
        .InputAutoComplete .input-control{
            border: 1px solid #d5d5d5;
        }
        .radio-button-cta-wrapper--single{
            padding: 10px 0;
        }
        input[type=radio]+label{
            padding-left: 40px;
        }
        .sr-form input[type=radio]+label:before, 
        form input[type=radio]+label:before{
            border: 1px solid #d5d5d5!important;
            border-radius: 50%!important;
            width: 28px!important;
            height: 28px!important;
        }
        .sr-form input[type=radio]:checked+label:before, 
        form input[type=radio]:checked+label:before{
            border: 1px solid #d5d5d5!important;
            background: transparent!important;
            border-radius: 50%!important;
        }
        .sr-form input[type=radio]:checked+label:after, 
        input[type=radio]:checked+label:after{
            width: 12px;
            height: 12px;
            left: 9px;
            border-radius: 50%;
        }
        .sr-form input[type=radio]+label:after, 
        form input[type=radio]+label:after{
            background: #101010;
        }
        .sr-form input[type=radio]+label:checked:after, 
        form input[type=radio]+label:checked:after{
            opacity: 1;
        }
        .financingBlockForm.is-open+.financingBlockForm,
        .financingBlockForm:last-child,
        .financing__content{
            box-shadow: none;
        }
        .financingBlockForm{
            background-color: #f5f5f5;
            border-bottom: 1px solid #d5d5d5;
        }
        .financingBlockForm.is-open{
            background-color: #ffffff;
        }
        .financing__control{
            padding: 35px 80px;
        }
        .sr-button-1.is-disabled {
            background: #e7e7e7!important;
            opacity: 1!important;
            color: #999999!important;
            border-color: #e7e7e7!important;
        }
        .financing__content-btn-section .sr-link{
            margin: 20px;
        }
        .listing-used-filters{
            border: 1px solid #d5d5d5;
        }
        .listing-used-filters-head{
            background-color: #f5f5f5;
        }
        .listing-used-filters .listing-used-filters-bloc{
            border-top: 1px solid #d5d5d5;
        }
        .listing-used-filters-head .listing-used-filters-head-title,
        .listing-used-filters-head .listing-used-filters-head-button p a,
        .listing-used-filters .listing-used-filters-bloc .listing-used-filters-title,
        .listing-used-filters .slider-labels,
        .listing-used-brands .brand-label,
        .model-list li .model-label,
        .listing-used-bodystyle,
        .transmission-button,
        .method-button-payment-bar button,
        .listing-used-results .number-results,
        .listing-tile .listing-tile-drivePowerTrains,
        .listing-tile .listing-tile-all-price{
            color: #101010;
        }
        .listing-used-payment-wrap{
            background-color: #f5f5f5;
        }
        .method-button-payment-bar{
            border-bottom: 1px solid #d1d1d1;
        }
        .listing-tile .listing-tile-image .has-warning-background {
            background-color: #101010;
        }
        .listing-used{
            background-color: #f5f5f5;
        }
        .wysiwyg a{
            color: #101010;
            text-decoration: underline;
            &:hover{
                color: #101010;
                text-decoration: none;
            }
        }
        .listing-used-button-loading.sr-button-1{
            background: #f5f5f5;
            &:hover{
                background: #910a2d;
                icon:last-child:first-child{
                    color: #ffffff;
                }
            }
        }
        .listing-used-button-loading icon:before{
            transition: none;
        }
        .model-button:hover {
            border-color: $borderColorSort;
        }
        .showroom-vdp-used .sr-button-2{
            color: #fff;
            background: #101010;
            padding: 14px;
            box-shadow: none;
            transition: none;
            border-radius: 3px;
            &:hover{
                opacity: 1;
                background: #910a2d;
            }
        }
        .vehicleBanner-personsViewed .is-price,
        .vehicleBanner-personsViewed .label{
            color: #ffffff;
        }
        .tile-payment-info .payment-rebates .payment-rebate{
            color: #999999;
            border-top: 1px solid #d5d5d5;
        }
        .showroom-vdp-used-options .showroom-vdp-used-options-list .showroom-vdp-used-options-item{
            color: #101010;
        }
        .vdp-information__title{
            border-bottom: 1px solid #d5d5d5;
        }
        .showroom-vdp-used-features-vehicle-info{
            border: 3px solid #d5d5d5;
            color: #999999;
        }
        .showroom-vdp-used-features-vehicle-info-value{
            color: #101010;
        }
        .showroom-vdp-used-features-vehicle-info-fuel-name{
            color: #101010;
        }
        .carfax-list .carfax-title{
            color: #101010;
        }
        .showroom-vdp-used .paymentCalculator__buttons{
            margin-right: 30px;
        }
        .payment-summary-section .listing-tile-all-price{
            border-top: 4px solid #101010;
        }
        .Appointment__content .button-cta-wrapper--single.selected {
            background-color: #101010;
        }
        .Appointment__content .button-cta-wrapper--single:hover {
            background-color: #f5f5f5;
            text-decoration: none;
        }
        .Appointment__content .button-cta-wrapper--single{
            border: 1px solid #d5d5d5;
            &:last-child{
                border-right: 1px solid #d5d5d5;
            }
        }
        .Appointment__content .appointment__steps--single{
            border-bottom: 1px solid #d5d5d5;
        }
        .Appointment__content .appointment__steps--single .timeSaved{
            color: #999999;
        }
        .checkout-payment-select input[type=radio]+label, 
        .checkout__section-delivery input[type=radio]+label{
            padding-left: 73px;
        }
        .checkout-payment-select input[type=radio]+label:after{
            left: 33px;
        }
        .no-delivery-option{
            background: #f5f5f5;
        }

        .checkout__section-delivery .forms-container,
        .checkout__inner-content{
            box-shadow: none;
            border: 1px solid #d5d5d5;
        }
        .checkout__section-delivery:not(:last-child){
            border-bottom: 0;
        }
        .checkout__section-delivery:before{
            background-color: #d5d5d5;
        }
        .checkout-payment-select:not(:last-child){
            border-bottom: 1px solid #d5d5d5;
        }
        label{
            color: #101010;
        }
        .checkout-payment-select .checkout__payment-options-list:nth-child(odd), 
        .checkout__section-delivery .checkout__payment-options-list:nth-child(odd) {
            background-color: #f5f5f5;
        }
        .SidebarSummary__listingAmount .sr-sidebarsummary .sr-title,
        .deliver-infos .sr-title{
            color: #101010!important;
        }
        .sr-sidebarsummary .SidebarSummary__listingAmount--item {
            color: #101010!important;
        }

        .section-checkout-header__progressBar-stage.validated{
            color: #101010;
            border-color: #101010;
        }
        .section-checkout-header__progressBar-stage.selected{
            background-color: #101010;
            border-color: #101010;
        }
        .section-checkout-header__progressBar-stage{
            color: #d5d5d5;
            border: 2px solid #d5d5d5;
        }
        .styled-placeholder.dropdown.field-error .Select-control{
            border: 2px solid #910a2d;
        }
        .InputText .errors-input,
        .error-forms,
        .styled-placeholder.dropdown .errors-input{
            color: #910a2d;
        }
        .checkout-financing__wrapper .checkout__content-title-wrapper{
            border-top: 1px solid #d5d5d5;
        }
        .financingBlockForm:not(.is-valid) h3{
            color: #101010!important;
        }
        .protection-single--content .protection-price--details{
            color: #999999;
        }
        .protection-single--content .protection-cta-selector{
            border: 1px solid #d5d5d5;
            box-shadow: none;
        }
        .protection-single--content .protection-cta-selector.selected{
            background-color: #910a2d;
            color: #ffffff;
            border-color: #910a2d;
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}
